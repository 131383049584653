import Rest from '../Rest'

export default class GoogleSearchService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/google_search'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  searchImages(
    query: string,
    start: number = 0,
    num: number = 9,
    searchType: string = 'image'
  ) {
    const params = {
      search_type: searchType,
      num: num,
      start: start
    }
    return this.get(`/search_images/${query}`, params).then(data => {
      return data
    })
  }
}
