
<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-9/12">
        <vs-input
          class="w-full"
          label
          v-model="query"
          @keyup.enter="enterPressed"
        />
      </div>
      <div class="vx-col w-3/12">
        <vs-button type="border" color="primary" @click="newSearch"
          >{{ $t('buscar') }}</vs-button
        >
      </div>
    </div>

    <div class="vx-row" v-show="images.length > 0" ref="imageListRef">
      <div class="vx-col w-full mr-4">
        <div
          class="grid grid-cols-3 gap-4 mt-4"
          v-for="imgs in chunkedImages"
          :key="imgs"
        >
          <vs-row v-for="img in imgs" :key="img">
            <vs-col
              class="w-full"
              vs-type="flex"
              vs-justify="center"
              vs-align="left"
              vs-w="1"
            >
              <img
                :src="img.image.thumbnailLink"
                @click="imageSelected(img)"
                style="cursor: pointer"
              />
            </vs-col>
          </vs-row>
        </div>
      </div>

      <vs-row>
        <vs-col
          class="w-full mt-6"
          vs-type="flex"
          vs-justify="center"
          vs-align="left"
          vs-w="1"
        >
          <vs-button
            type="filled"
            color="primary"
            @click="loadMore"
            :disabled="disableLoadMore"
            >{{ $t('carregar-mais') }}</vs-button
          >
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>


<script>
import GoogleSearchService from '@/services/api/GoogleSearchService'

export default {
  props: {
    option_id: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    googleSearchService: null,
    query: '',
    images: [],
    start: 0,
    num: 9,
    disableLoadMore: false,
  }),
  computed: {
    chunkedImages() {
      return _.chunk(this.images, 3)
    },
  },
  watch: {},
  methods: {
    newSearch() {
      this.disableLoadMore = false
      this.start = 0
      this.search(false)
    },
    search(more) {
      this.$vs.loading()
      this.googleSearchService
        .searchImages(this.query, this.start)
        .then((response) => {

          let items = response.items
          items = items.filter(item => {
            return item.mime !== 'image/'
          })

          this.images = more
            ? this.images.concat(items)
            : items
            
          if (response.items.length < this.num) {
            this.disableLoadMore = true
          }
          this.$vs.loading.close()
        }, error => {
          this.$vs.loading.close()
        })
    },
    loadMore() {
      this.start = this.start + this.num
      this.search(true)
    },
    imageSelected(image) {
      console.log('image...', image)
      this.$emit('onImageSelected', image, this.option_id)
    },
    enterPressed() {
      this.newSearch()
    },
  },
  beforeMount() {
    this.googleSearchService = GoogleSearchService.build(this.$vs)
  },
  mounted() {},
}
</script>